export default {
    created () {
        this.$youtubeHelperPromise.then(youtubeHelper => {
            this.videos = youtubeHelper.videos.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            this.playlists = youtubeHelper.playlists;
        });
    },

    data() {
        return {
            videos: [],
            playlists: [],
        }
    },

    methods: {
        hasVideos () {
            return this.videos && this.videos.length > 0;
        },
        getFbPolExplainedOneVideos () {
            return this.videos.filter(video => video.playlistId == "PLTNJwCTuWB7TH5YmuRBT49b7iiUj4TYl1");
        },
        getFbPolExplainedTwoVideos () {
            return this.videos.filter(video => video.playlistId == "PLTNJwCTuWB7R8wDjfbUcgeKk5SLZ4O2Jq");
        },
        getFbPolExplainedThreeVideos () {
            return this.videos.filter(video => video.playlistId == "PLTNJwCTuWB7SIKq5de0ZxPU96c3N32frB");
        },           
        getFbC2CVideos () {
            return this.videos.filter(video => video.playlistId == "PLTNJwCTuWB7SKeuwO2iXB6m83gI1jIMD9");
        },        
        getFbLatestVideos(skip, take) {
            return this.videos.slice(skip, take+skip);
        },
        getFbFeaturedVideos(skip, take) {
            return this.videos.filter(video => video.playlistId == "3083463885227458").slice(skip, take+skip);
        },
        getVideo (videoId) {
            return this.videos.find(video => video.id == videoId);
        },
        buildVideoObject (id, title, description, createdDate, width, height, thumbnailUrl, duration) {
            return { 
                id, 
                title, 
                description, 
                createdDate, 
                width, 
                height, 
                thumbnailUrl, 
                duration 
            };
        },
        toggleYoutubeVideoPopup (videoId) {
            this.toggleVideoDOMNode(this.generateYoutubeVideoFrameHtmlElement(videoId));
        },
        toggleVideoDOMNode(iframe) {
            var videoDOMNode = document.getElementById('video_player');
            if(videoDOMNode) videoDOMNode.remove();
            else {
                var videoPlayer = document.createElement("div"); 
                videoPlayer.id = "video_player";
                videoPlayer.classList.add('video_player--open');
                videoPlayer.addEventListener("click", this.toggleVideoDOMNode.bind(null), false);
                videoPlayer.appendChild(iframe);
                document.body.appendChild(videoPlayer);
            }
        },
        generateYoutubeVideoFrameHtmlElement(videoId) {
            var video = this.getVideo(videoId);
            var videoFrameSize = this.calculateFrameSize(video.width, video.height);
            var iframe = document.createElement("iframe");     
            iframe.setAttribute("src", encodeURI("https://www.youtube.com/embed/" + videoId));
            iframe.setAttribute("width", videoFrameSize.width);            
            iframe.setAttribute("height", videoFrameSize.height);
            iframe.setAttribute("frameborder", 0);
            iframe.setAttribute("allowFullScreen", "true");
            return iframe;
        },        
        calculateFrameSize(videoWidth, videoHeight) {
            const VIEWPORT_MARGIN = 0.25;
            var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            var maxFrameWidth = Math.min(viewportWidth * (1 - VIEWPORT_MARGIN), videoWidth);
            var maxFrameHeight = Math.min(viewportHeight * (1 - VIEWPORT_MARGIN), videoHeight);
            var biggestBoundReduction = Math.max(videoWidth / maxFrameWidth, videoHeight / maxFrameHeight);
            var adjustedWidth = Math.round(videoWidth / biggestBoundReduction);
            var adjustedHeight = Math.round(videoHeight / biggestBoundReduction);
            return { width: adjustedWidth, height: adjustedHeight };
        },
        getFormatedDate (stringDate) {
            var date = new Date(stringDate);
            const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        },
        getMinutesFromSeconds(seconds) {
            return Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);
        },
        getCroppedText (text, maxCharacters) {
            return text ? (
                (text.length <= maxCharacters) ? text 
                    : (text.slice(0, maxCharacters) + "..."))
                : "";
        }
    }
}