<template>
<div>
	<div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-about.jpg') + ')'">
		<h1 class="cover__title">Meet Aaron</h1>
	</div>
	<div class="section wrapper">
		<h1 class="title" style="margin-bottom: 20px">Aaron is a lifelong advocate for taxpayers and common sense.</h1>
		<div class="grid">
			<div class="grid__item order-second-mobile">
				<div class="box">
					<img src="@/assets/endorsement-bob.jpg" alt="Bob Zimmer" />
					<br><br>
					<p class="text text--bold">Bob Zimmer | Member of Parliament</p>
					<p class="text">“Aaron Gunn is exactly the type of advocate North Island – Powell River deserves. Whether it’s being a voice for B.C. forestry, safer communities, law-abiding firearms owners or a defender of free speech, I am confident Aaron will relentlessly fight for the people of his riding.”</p>
				</div>
			</div>
			<div class="grid__item">
				<p class="text">After serving in the Canadian Army Reserves and working with the Canadian Taxpayers Federation, Aaron went on to produce a series of hugely successful documentaries, along the way building the largest social media following of any political figure in B.C.</p>
				<p class="text">With millions of viewers, Aaron has been a voice of reason for hardworking taxpayers who have been consistently thrown under the bus by the Trudeau-NDP coalition.</p>
				<p class="text">Whether it’s <a class="link" @click.prevent="toggleYoutubeVideoPopup(getVideoFishingComputed.id)" href="#">advocating for fishermen</a>, forestry workers or <a class="link" @click.prevent="toggleYoutubeVideoPopup(getVideoJusticeComputed.id)" href="#">victims of violent crime</a>, Aaron has dedicated his life to uncovering the truth and investigating the issues that matter to Canadians.</p>
				<p class="text">Throughout, Aaron has demonstrated his commitment to lower taxes, less waste and a stronger Canada.</p>
				<p class="text">His videos, which are filmed across the country, have included interviews with over 300 prominent Canadians, including Rex Murphy, Ellis Ross and Pierre Poilievre - three times!</p>
				<img src="@/assets/about-image-1.jpg" alt="About Aaron"/>
				<br><br>
				<p class="text">Aaron first sat down with Pierre back in 2021 <a class="link" href="https://www.facebook.com/watch/?v=434526197975013" target="_blank">(an interview that earned nearly 1 million views!)</a> and has since gotten to know the man he believes will be Canada’s next Prime Minister.</p>
				<p class="text">Having lived his entire life on Vancouver Island, Aaron is a proud resident of Campbell River, located within the riding of North Island—Powell River.</p>
				<p class="text">As your next Member of Parliament, Aaron looks forward to being your voice in Ottawa and going to work for you: the people of this riding. </p>
				<p class="text">But to defeat the NDP and bring common sense back to Canada, he needs your help.</p>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import videosMixin from '@/videos-mixin.js'
export default {
	mixins: [videosMixin],
	components: {

	},
	created() {

	},
	data() {
		return {

		}
	},
	computed: {
      getVideoFishingComputed () {
         return this.getVideo("IPHDyscI7wo");
      },
		getVideoJusticeComputed () {
         return this.getVideo("PT8OU8Yhs_s");
      },
	}

}
</script>

<style scoped>


@media (max-width: 1100px) {
	.order-second-mobile { order: 2; }
}

</style>
